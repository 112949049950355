import { ThemeMode } from '@unbounded/unbounded-components'
import { MONACO_DEFAULT_FONT_SIZE } from '~consts'
import { CURRENT_PLATFORM_TYPE, Platform } from '~routes/platform'
import type { EditorThemeContextProps, themeDataProps, themeProps } from './EditorTheme.types'
import { getDefaultThemes } from './EditorTheme.utils'
import miPasaTheme from './themes/MiPasa (light).json'
import papillonTheme from './themes/Papillon (light).json'
import cryptoTheme from './themes/Pasa Network (dark).json'
import dpasaTheme from './themes/dPasa (dark).json'

const defaultThemes = getDefaultThemes()

export const DEFAULT_EDITOR_THEME = defaultThemes.default
export const DEFAULT_INVERSE_EDITOR_THEME = defaultThemes.inverse

export const PREDEFINED_THEMES: themeProps[] = [
  DEFAULT_EDITOR_THEME,
  DEFAULT_INVERSE_EDITOR_THEME,
  {
    id: 'abyss',
    title: 'Abyss',
    mode: ThemeMode.dark,
  },
  {
    id: 'darcula',
    title: 'Darcula',
    mode: ThemeMode.dark,
  },
  {
    id: 'vs-dark',
    title: 'Dark (Visual Studio)',
    mode: ThemeMode.dark,
  },
  {
    id: 'kimbie-dark',
    title: 'Kimbie Dark',
    mode: ThemeMode.dark,
  },
  {
    id: 'monokai',
    title: 'Monokai',
    mode: ThemeMode.dark,
  },
  {
    id: 'monokai-dimmed',
    title: 'Monokai Dimmed',
    mode: ThemeMode.dark,
  },
  {
    id: 'solarized-dark',
    title: 'Solarized (dark)',
    mode: ThemeMode.dark,
  },
  {
    id: 'tomorrow-night-blue',
    title: 'Tomorrow Night Blue',
    mode: ThemeMode.dark,
  },
  {
    id: 'vs',
    title: 'Light (Visual Studio)',
    mode: ThemeMode.light,
  },
  {
    id: 'quiet-light',
    title: 'Quiet Light',
    mode: ThemeMode.light,
  },
  {
    id: 'solarized-light',
    title: 'Solarized (light)',
    mode: ThemeMode.light,
  },
]

const editorThemes: Record<Platform, themeDataProps> = {
  [Platform.mipasa]: miPasaTheme as themeDataProps,
  [Platform.papillon]: papillonTheme as themeDataProps,
  [Platform.crypto]: cryptoTheme as themeDataProps,
  [Platform.dpasa]: dpasaTheme as themeDataProps,
}

export const DEFAULT_EDITOR_THEME_DATA = editorThemes[CURRENT_PLATFORM_TYPE]
export const CUSTOM_EDITOR_THEME_ID = 'custom'
export const CUSTOM_EDITOR_THEME_NAME = 'Custom theme'

export const DEFAULT_EDITOR_THEME_CONTEXT: EditorThemeContextProps = {
  theme: DEFAULT_EDITOR_THEME,
  themeData: DEFAULT_EDITOR_THEME_DATA,
  customThemeData: undefined,
  getEditorThemes: () => PREDEFINED_THEMES,
  setEditorTheme: () => false,
  editorFontSize: MONACO_DEFAULT_FONT_SIZE,
  setEditorFontSize: () => {},
}

export const LS_KEY_THEME = 'codeTheme'
export const LS_KEY_THEME_CUSTOM_DATA = 'codeThemeCustomData'
export const LS_KEY_EDITOR_FONT_SIZE = 'codeEditorFontSize'
