import type { JSONContent } from '@tiptap/react'
import type {
  AdminActivityLog,
  AdminRole,
  AdminUser,
  AdminUserActionLog,
  AdminWalletTransaction,
  BackendSlackChannel,
  BinanceConnection,
  Feature,
  FeedEntry,
  FileEmbed,
  GitHubConnection,
  Invite,
  InviteLocation,
  NotebookAnalysisNode,
  NotebookComment,
  NotebookCommentContent,
  NotebookCommentReaction,
  NotebookPermission,
  NotificationEntry,
  PaginatedResult,
  PaginationOptions,
  ProjectAccessRequest,
  ProjectDirectoryMetadata,
  ProjectFileMetadata,
  ProjectInvite,
  ProjectSyncResponse,
  ProjectTeamAccess,
  ProjectUserAccess,
  Publication,
  PublicationsAccessLevel,
  PublicationType,
  RatePlan,
  RatePlanName,
  Referral,
  ReferralUser,
  Self,
  Subscription,
  Tag,
  Team,
  TeamInvite,
  TeamJoinRequest,
  TeamJoinRequestStatus,
  TeamMember,
  TeamMemberPermission,
  TeamMembershipStatus,
  TeamProject,
  Tip,
  TradingAsset,
  TradingAssetData,
  TrialInformation,
  UserFollow,
  UserInfo,
  UserPreferences,
  WalletTransactionKind,
  WalletTransactionType,
} from '~api/common/backend'
import {
  AdminSubscriptionsStat,
  AdminUserRole,
  ExportFormat,
  ExportTheme,
  ExternalPublishSettings,
  InviteLocationType,
  NotebookAnalysisNodeType,
  PublicationSchedule,
  PublicationScheduleType,
  RatePlanFeatures,
  SignupStat,
  SubscriptionStats,
  TeamInviteRequest,
  TeamMemberRequest,
  TradingBotChannel,
} from '~api/common/backend'
import { getFileCodingLanguageRecord } from '~components/code/file-explorer/explorer-utils'
import type { NotebookStrategyAsset } from '~components/code/StrategyEditor/StrategyEditor.types'
import { CodingLanguage, CodingType } from '~components/code/type'
import type { OpenAPISpec } from '~components/openapi/OpenAPI.types'
import type { ExchangeType } from '~data/exchanges'
import type { Execution } from '~data/execution/execution-type'
import type { File, FileVersion } from '~data/file/file-type'
import { ViewMode } from '~data/listing'
import type { Project, ProjectBasicInfo, ProjectPermission, ProjectPortfolioAsset, ProjectPortfolioMeta, ProjectSyncConfig } from '~data/projects'
import type { HTTPMethods } from '~utils/authorized-fetch'

export interface MiPasaUser {
  id: string
  avatar?: string
  avatar_icon?: string
  first_name: string
  last_name: string
  link: string
  email: string
  email_is_confirmed: boolean
  user_name: string
  admin?: boolean
  headline: string | null
  social: {
    blog_url: string | null
    facebook_url: string | null
    linkedin_url: string | null
    twitter_url: string | null
    website_url: string | null
  }
  company: {
    name: string | null
    position: string | null
  }
  following_by_you?: boolean
  followers_count: number
  is_discoverable: boolean
  is_suspended: boolean
  referral_code: string
  is_idm_managed?: boolean
  is_telegram_managed?: boolean
  is_organitz_managed?: boolean
  is_guest?: boolean
}

export interface MiPasaPaginatedResult<T> {
  entries: T[]
  page_number: number
  page_size: number
  total_entries: number
  total_pages: number
}

export interface MiPasaNotebookPaginatedResult extends MiPasaPaginatedResult<MiPasaNotebook> {
  tags: Tag[]
}

export interface MiPasaPublicationsPaginatedResult extends MiPasaPaginatedResult<MiPasaPublication> {
  tags: Tag[]
}

export interface MiPasaProjectSyncConfig {
  id: string
  provider: string
  repo: string
  branch: string
  directory?: string
  synced_at: string
  synced_version: string
}

export interface MiPasaProjectSyncResponse {
  local_changes: string[]
  remote_changes: string[]
  sync_config: MiPasaProjectSyncConfig
}

export interface MiPasaNotebookBase {
  id: string
  description: string | null
  analyze_count: number
  clone_count: number
  comment_count: number
  execute_count: number
  follow_count: number
  share_count: number
  view_count: number

  created_at: string
  created_by: MiPasaUser

  owned_at: string
  owned_by: MiPasaUser

  license: string | null
  name: string
  language: CodingLanguage | null
  section: string

  published_at: string
  is_public: boolean
  public_url: string
  public_url_slug?: string | null
  parent_id: string

  setup_complete: boolean

  tags: Array<Tag>
  thumbnail: {
    icon_url?: string
    original_url?: string
  }

  updated_at: string

  cells?: Array<unknown>

  default_file_id?: string
}

export interface MiPasaProjectPortfolioMeta {
  assets: Array<ProjectPortfolioAsset>
  trade_asset?: string
  trade_asset_icon?: string
  holdings: Record<string, number>
  total_deposit: number
  total_withdrawal: number
  exchange?: ExchangeType
}

export interface MiPasaNotebook extends MiPasaNotebookBase {
  is_published?: boolean
  permissions?: Record<string, boolean>
  published_clone_id?: string | null
  authors?: MiPasaUser[]
  sync_config?: MiPasaProjectSyncConfig
  portfolio_meta?: MiPasaProjectPortfolioMeta
  latest_ok_version_id?: string
  latest_version_id?: string
}

export interface MiPasaTeamNotebook extends MiPasaNotebook {
  team_permission_id: string
}

export interface MiPasaSubscription {
  id: string
  expiration_date: string | null
  next_billing_date: string | null
  canceled_at: string | null
  rate_plan: MiPasaRatePlan
  is_default?: boolean
}

export interface MiPasaRatePlan {
  id: string
  is_highlighted: boolean
  price_in_units: number
  title: RatePlanName
  details: string
  features: RatePlanFeatures
}

export interface MiPasaDirectoryEntry {
  basename: string
  type: 'directory'
  metadata: ProjectDirectoryMetadata
}

export interface MiPasaFileEntry {
  basename: string
  type: 'file'
  file: MiPasaFileWithContent
}

export interface MiPasaFileInfo {
  id: string
  name: string
  language: string
  mime_type: string
  metadata: ProjectFileMetadata
  created_at: string
}

export type MiPasaFileFormat = 'base64' | 'text'
export interface MiPasaFileUpdateInfo {
  content: string
  format: MiPasaFileFormat
  language: MiPasaFileInfo['language']
  mime_type: MiPasaFileInfo['mime_type']
  name: MiPasaFileInfo['name']
}

export type MiPasaFileWithContent = MiPasaFileInfo & MiPasaFileUpdateInfo

export interface MiPasaFileVersion {
  id: string
  name: string | null
  created_at: string
  revisioned_at: string | null
  file: MiPasaFileWithContent
  payload: string
}

export interface MiPasaUserAboutPage {
  content_rich_draft?: JSONContent | null
  content_rich: JSONContent | null
}

export interface MiPasaTrialInformation {
  enabled: boolean
  disabled_reason: 'deactivated' | 'expired' | null
  expires_at: string | null
  expiry_days: string | null
  rate_plan_id: string | null
  features: Array<Feature>
}

export interface MiPasaSelf {
  api_key: string
  id: string
  token: string
  user: MiPasaUser
  is_guest: boolean
  guest_id: string | null
  masked?: boolean
  github?: MiPasaGitHubConnection
  slack: MiPasaSlackConnection | null
  preferences?: UserPreferences
  features?: Feature[]
  trial?: MiPasaTrialInformation
  auth_error?: string | null
}

export interface MiPasaBalance {
  user_id: string
  balance: number
}

export interface MiPasaNotebookRun {
  ended_at: string | null
  started_at: string
  started_by: MiPasaUser
  environment: string
  execution_type: 'on_demand' | 'scheduled'
  id: string
  number: number
  status: 'in_progress' | 'ok' | 'error' | 'aborted'
  version?: {
    id: string
    name: string
  }
  file?: {
    id: string
    name: string
  }
}

export interface MiPasaUserPermission {
  id: string
  permissions: string[]
  user: MiPasaUser
  author: boolean
  created_at: string
  is_owner: boolean
}

export interface MiPasaTeamPermission {
  id: string
  permissions: string[]
  team: MiPasaTeam
  created_at: string
}

export interface MiPasaEventEntry {
  type: string
  resource_id: string
}

export interface MiPasaUpload {
  id: string
  url: string
  method: HTTPMethods
}

export interface MiPasaTeam {
  id: string
  name: string
  created_at: string
  updated_at: string
  created_by: MiPasaUser
  is_discoverable: boolean
  approvable_join: boolean
  hidden: Array<string>
  thumbnail: {
    icon_url?: string | null
    original_url?: string | null
  }
  permissions: Record<string, boolean>
  membership_status: TeamMembershipStatus
  contact_email?: string | null
  description?: string | null
  users_count?: number | null
  projects_count?: number | null
  last_members?: Array<{ user: MiPasaUser; permissions: Array<'manage' | 'view'> }>
}

export interface MiPasaTeamMemberRequest {
  user_id: string
  permissions: Array<TeamMemberPermission>
}

export interface MiPasaTeamDraft {
  name: string
  description?: string
  contact_email?: string
  is_discoverable?: boolean
  approvable_join?: boolean
  members?: Array<MiPasaTeamMemberRequest>
  invites?: Array<TeamInviteRequest>
}

export interface MiPasaTeamMember {
  id: string
  permissions: Array<TeamMemberPermission>
  user: MiPasaUser
  created_at: string
}

export interface MiPasaTeamJoinRequest {
  id: string
  team_id: string
  created_at: string
  status: TeamJoinRequestStatus
  user: MiPasaUser
  reason?: string
}

export interface MiPasaProjectAccessRequest {
  id: string
  user: MiPasaUser
  created_at: string
  reason: string
  status: string
}

export interface MiPasaProjectInvite {
  id: string
  permissions: Array<NotebookPermission>
  email: string
  created_at: string
}

export interface MiPasaFeedEntry {
  id: string
  action: string
  target: {
    id: string
    type: string
    name: string
  }
  performed_at: string
  user: MiPasaUser
  performed_by: MiPasaUser
}

export interface MiPasaTeamInvite {
  id: string
  permissions: Array<TeamMemberPermission>
  email: string
  created_at: string
}

export interface MiPasaGenericInviteLocation {
  type: 'generic'
}

export interface MiPasaTeamInviteLocation {
  type: 'single_team'
  id: string
}

export interface MiPasaProjectInviteLocation {
  type: 'single_notebook'
  id: string
}

export type MiPasaInviteLocation = MiPasaGenericInviteLocation | MiPasaTeamInviteLocation | MiPasaProjectInviteLocation

export interface MiPasaInvite {
  id: string
  email: string
  created_at: string
  to: MiPasaInviteLocation
}

export interface MiPasaNotebookComment {
  author: MiPasaUser
  created_at: string
  id: string
  reaction_by_you: NotebookCommentReaction
  likes_count: number
  dislikes_count: number
  reply_to: string
  content: NotebookCommentContent
  replies?: NotebookComment[]
  is_deleted: boolean
}

export interface MiPasaUserFollow {
  follower: MiPasaUser
  following_by_you: boolean
}

export interface MiPasaGitHubConnection {
  is_connected: boolean
  user_name: string | null
  avatar: string | null
}

export interface MiPasaBinanceConnection {
  is_connected: boolean
}

export interface MiPasaSlackConnection {
  team_name: string | null
}

export interface MiPasaNotificationEntryTeam {
  id: string
  name: string
}

export interface MiPasaNotificationEntry {
  type: string
  created_at: string
  id: string
  metadata: Record<string, string>
  entity?: {
    id: string
    type: string
    name: string
    link: string
    slug: string
  }
  seen_at: string | null
  source_user?: MiPasaUser
  subject_user?: MiPasaUser
  subject_team?: MiPasaNotificationEntryTeam
}

export interface MiPasaPublication {
  id: string
  project_id: string
  file_id: string
  version_id: string
  type: PublicationType
  access_level: PublicationsAccessLevel
  published_at: string
  published_changes_at: string
  slug: string
  title: string
  description: string | null
  cover_image: string | null
  view_count: number
  share_count: number
  tags: Array<{ id: string; name: string }>
  payload?: string
  pasa_info?: {
    openapi_spec: OpenAPISpec
    portfolio_assets?: Array<NotebookStrategyAsset>
    portfolio_1d?: number
    portfolio_7d?: number
    portfolio_30d?: number
    portfolio_max?: number
    portfolio_sharpe_ratio?: number
    portfolio_1d_chart?: Array<{ date: string; value: number }>
    portfolio_7d_chart?: Array<{ date: string; value: number }>
    portfolio_30d_chart?: Array<{ date: string; value: number }>
    portfolio_max_chart?: Array<{ date: string; value: number }>
    portfolio_1d_days?: number
    portfolio_7d_days?: number
    portfolio_30d_days?: number
    portfolio_max_days?: number
    portfolio_asset_categories?: Record<string, Array<string>>
    portfolio_asset_prices?: Record<string, number>
  }
  portfolios?: Array<ProjectBasicInfo>
  authors: Array<MiPasaUser>
  claps_count?: number
  clapped_by_you?: boolean
  owner: MiPasaUser
  external_providers: Publication['externalProviders']
  permissions?: Record<string, boolean>
  execution_enabled: boolean
  execution_copy_project_files: boolean
  execution_autorun_after_cell_changed: boolean
}

export interface MiPasaExternalPublishSettings {
  enabled: boolean
  cell_ids: string[]
  title?: string
  print_format: ExportFormat
  print_theme: ExportTheme
  print_width: number
  layout_header_text: string | null
  layout_header_date: boolean
  layout_footer_text: string | null
  slack_settings?: { enabled: boolean; channel_name?: string; channel_id?: string }
  email_settings?: { enabled: boolean; emails?: string[] }
}

export interface MiPasaFileEmbedOptions {
  execution_enabled: boolean
  execution_copy_project_files: boolean
}

export interface MiPasaFileEmbed extends MiPasaFileEmbedOptions {
  embed_id: string | null
  project_id: string | null
  project_file_id: string | null
}

export interface MiPasaPublicationSchedule {
  type: 'one_off' | 'recurring'
  enabled?: boolean
  starts_at?: string
  next_run_at?: string
  last_run_at?: string
  interval_type?: 'minute' | 'hour' | 'day' | 'week' | 'month'
  interval_value?: number
  allowed_days?: number[]
}

export interface AdminMiPasaUser {
  id: string
  name: string
  email: string
  user_name: string
  signup_date: string
  active_at?: string
  balance: number
  role: 'user' | 'admin'
  roles: AdminRole[]
  features: Feature[]
  subscription: null | {
    id: string
    title: string
  }
  is_suspended: boolean
  avatar?: string
  avatar_icon?: string
  is_guest: boolean
  guest_id: string | null
}

export interface AdminMiPasaTransaction {
  id: string
  type: WalletTransactionType
  kind: WalletTransactionKind
  amount_in_units: number
  inserted_at: string
}

export interface AdminMiPasaActionLog {
  id: string
  inserted_at: string
  action: 'suspend' | 'unsuspend'
  user: AdminMiPasaUser
  admin: AdminMiPasaUser
  metadata: Record<string, unknown>
}

export interface AdminMiPasaActivityLog {
  id: string
  inserted_at: string
  type: string
  user?: AdminMiPasaUser
  metadata: Record<string, unknown>
}

export interface MiPasaSlackChannel {
  id: string
  name: string
  is_private: boolean
}

export interface MiPasaBackendSlackChannelsResponse {
  entries: MiPasaSlackChannel[]
  next_cursor: string
}

export interface MiPasaTip {
  id: string
  name: string
  description: string | null
  thumbnail: {
    icon_url?: string
    original_url?: string
  }
}

export interface MiPasaTradingAsset {
  remote_type: string
  remote_id: string
  symbol: string
  description: string
  image_url?: string | null
  market_cap?: number
  price?: number
  age?: number
}

export interface MiPasaTradingAssetData {
  market_caps: Array<[number, number]>
  prices: Array<[number, number]>
  total_volumes: Array<[number, number]>
}

export interface MiPasaSubscriptionBaseStat {
  strategy_id: string
  strategy_title: string
  strategy_slug: string
  strategy_image?: string
}

export interface MiPasaSubscriptionView extends MiPasaSubscriptionBaseStat {
  views: number
}

export interface MiPasaSubscriptionSub extends MiPasaSubscriptionBaseStat {
  subscriptions: number
}

export interface MiPasaSubscriptionStats {
  views: MiPasaSubscriptionView[]
  subscriptions: MiPasaSubscriptionSub[]
}

export interface MiPasaSignupStat {
  id: string
  first_name: string
  last_name: string
  user_name: string
}

export interface MiPasaAdminSubscriptionsStat {
  user_id: string
  user_name: string
  user_first_name: string
  user_last_name: string
  user_referral_code: string
  user_email: string
  views: number
  subscriptions: number
}

export interface MiPasaAdminSubscriptionStatsResult extends MiPasaPaginatedResult<MiPasaAdminSubscriptionsStat> {
  total_views: number
  total_subs: number
}

export interface MiPasaAdminReferralUser {
  user: AdminMiPasaUser
  trial: MiPasaTrialInformation
}

export function mapAdminSubscriptionStat(mipasaStat: MiPasaAdminSubscriptionsStat): AdminSubscriptionsStat {
  return {
    userId: mipasaStat.user_id,
    userName: mipasaStat.user_name,
    userFirstName: mipasaStat.user_first_name,
    userLastName: mipasaStat.user_last_name,
    userReferralCode: mipasaStat.user_referral_code,
    userEmail: mipasaStat.user_email,
    views: mipasaStat.views,
    subscriptions: mipasaStat.subscriptions,
  }
}

export function mapSignupStat(mipasaStat: MiPasaSignupStat): SignupStat {
  return {
    id: mipasaStat.id,
    firstName: mipasaStat.first_name,
    lastName: mipasaStat.last_name,
    userName: mipasaStat.user_name,
  }
}

export function mapSubscriptionStats(mipasaStats: MiPasaSubscriptionStats): SubscriptionStats {
  const views = mipasaStats.views.map(v => ({
    strategyId: v.strategy_id,
    strategyTitle: v.strategy_title,
    strategySlug: v.strategy_slug,
    strategyImage: v.strategy_image,
    views: v.views,
  }))

  const subscriptions = mipasaStats.subscriptions.map(v => ({
    strategyId: v.strategy_id,
    strategyTitle: v.strategy_title,
    strategySlug: v.strategy_slug,
    subscriptions: v.subscriptions,
  }))

  return { views, subscriptions }
}

export function mapExternalPublicationSettings(mipasaSettings: MiPasaExternalPublishSettings): ExternalPublishSettings {
  return {
    enabled: mipasaSettings.enabled,
    cellIds: mipasaSettings.cell_ids,
    title: mipasaSettings.title,
    printFormat: mipasaSettings.print_format,
    printTheme: mipasaSettings.print_theme,
    printWidth: mipasaSettings.print_width,
    layoutHeaderText: mipasaSettings.layout_header_text,
    layoutHeaderDate: mipasaSettings.layout_header_date,
    layoutFooterText: mipasaSettings.layout_footer_text,
    slackSettings: {
      enabled: Boolean(mipasaSettings.slack_settings?.enabled),
      channelId: mipasaSettings.slack_settings?.channel_id,
      channelName: mipasaSettings.slack_settings?.channel_name,
    },
    emailSettings: {
      enabled: Boolean(mipasaSettings.email_settings?.enabled),
      emails: mipasaSettings.email_settings?.emails,
    },
  }
}

export function mapFileEmbed(miPasaFileEmbed: MiPasaFileEmbed): FileEmbed {
  return {
    id: miPasaFileEmbed.embed_id ?? undefined,
    projectId: miPasaFileEmbed.project_id ?? undefined,
    projectFileId: miPasaFileEmbed.project_file_id ?? undefined,
    isExecutionEnabled: miPasaFileEmbed.execution_enabled,
    isFileCopyEnabled: miPasaFileEmbed.execution_copy_project_files,
  }
}

export function mapPublicationSchedule(mipasaSchedule: MiPasaPublicationSchedule): PublicationSchedule {
  const t = mipasaSchedule.type === 'recurring' ? PublicationScheduleType.recurring : PublicationScheduleType.oneOff
  const it = mipasaSchedule.interval_type ?? (t === PublicationScheduleType.recurring ? 'hour' : undefined)
  const iv = mipasaSchedule.interval_value ?? (t === PublicationScheduleType.recurring ? 1 : undefined)

  return {
    type: t,
    enabled: mipasaSchedule.enabled,
    startsAt: mipasaSchedule.starts_at ? new Date(mipasaSchedule.starts_at) : undefined,
    lastRunAt: mipasaSchedule.last_run_at ? new Date(mipasaSchedule.last_run_at) : undefined,
    nextRunAt: mipasaSchedule.next_run_at ? new Date(mipasaSchedule.next_run_at) : undefined,
    intervalType: it,
    intervalValue: iv,
    allowedDays: mipasaSchedule.allowed_days,
  }
}

export function mapMiPasaTip(mitip: MiPasaTip): Tip {
  return {
    id: mitip.id,
    name: mitip.name,
    description: mitip.description,
    preview: mitip.thumbnail.icon_url || null,
  }
}

export function mapSlackChannel(ch: MiPasaSlackChannel): BackendSlackChannel {
  return {
    id: ch.id,
    name: ch.name,
    isPrivate: ch.is_private,
  }
}

export function mapPaginatedResponse<T, U>(page: MiPasaPaginatedResult<T>, cb: (a: T) => U): PaginatedResult<U> {
  return {
    page: page.page_number,
    perPage: page.page_size,
    totalPages: page.total_pages,
    totalEntries: page.total_entries,
    entries: page.entries.map(cb),
  }
}

export function mapAdminActivityLogMetadata(log: AdminMiPasaActivityLog): AdminActivityLog['metadata'] {
  if (log.type === 'notebook.ownership_changed') {
    return {
      ...log.metadata,
      new_owner: log.metadata.new_owner ? mapAdminUser(log.metadata.new_owner as AdminMiPasaUser) : undefined,
      previous_owner: log.metadata.previous_owner ? mapAdminUser(log.metadata.previous_owner as AdminMiPasaUser) : undefined,
    }
  }

  if (log.type === 'notebook.permissions_changed') {
    const newSubject = { ...(log.metadata.subject as any) }

    if (newSubject?.type === 'user' && newSubject?.data) {
      newSubject.data = mapAdminUser(newSubject.data)
    }

    return {
      ...log.metadata,
      subject: newSubject,
    }
  }

  if (log.type === 'publication.viewed') {
    const newReferral = log.metadata.referral as AdminMiPasaUser | undefined

    return {
      ...log.metadata,
      referral: newReferral ? mapAdminUser(newReferral) : undefined,
    }
  }

  return log.metadata as AdminActivityLog['metadata']
}

export function mapAdminActivityLog(log: AdminMiPasaActivityLog): AdminActivityLog {
  return {
    id: log.id,
    type: log.type as AdminActivityLog['type'],
    insertedAt: new Date(`${log.inserted_at}Z`),
    user: log.user ? mapAdminUser(log.user) : undefined,
    metadata: mapAdminActivityLogMetadata(log),
  } as AdminActivityLog
}

export function mapAdminActionLog(log: AdminMiPasaActionLog): AdminUserActionLog {
  return {
    id: log.id,
    action: log.action,
    insertedAt: new Date(log.inserted_at),
    user: mapAdminUser(log.user),
    admin: mapAdminUser(log.admin),
    metadata: log.metadata,
  }
}

export function mapAdminTransaction(trans: AdminMiPasaTransaction): AdminWalletTransaction {
  return {
    id: trans.id,
    type: trans.type,
    kind: trans.kind,
    amount: trans.amount_in_units,
    insertedAt: new Date(trans.inserted_at),
  }
}

export function mapRole(role: AdminMiPasaUser['role']): AdminUser['role'] {
  switch (role) {
    case 'user':
      return AdminUserRole.user
    case 'admin':
      return AdminUserRole.admin
    default:
      throw new Error(`Role ${role} not recorgnized`)
  }
}

export function mapAdminUser(user: AdminMiPasaUser): AdminUser {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    userName: user.user_name,
    signupDate: new Date(`${user.signup_date}Z`),
    activeAt: user.active_at ? new Date(`${user.active_at}Z`) : undefined,
    balance: user.balance,
    role: mapRole(user.role),
    roles: user.roles,
    features: user.features,
    subscription: user.subscription,
    isSuspended: user.is_suspended,
    avatar: user.avatar,
    avatarIcon: user.avatar_icon,
    isGuest: user.is_guest,
    guestId: user.guest_id || undefined,
  }
}

export function mapUser(user: MiPasaUser): UserInfo {
  const actualUsername = user.user_name || user.link.split('/').pop() || 'unknown'

  return {
    id: user.id,
    headline: user.headline || undefined,
    username: actualUsername,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    avatar: user.avatar,
    avatarIcon: user.avatar_icon,
    link: user.link,
    followingByYou: user.following_by_you,
    followersCount: user.followers_count,
    isDiscoverable: user.is_discoverable,
    referralCode: user.referral_code,
    isIDMManaged: user.is_idm_managed,
    isTelegramManaged: user.is_telegram_managed,
    isOrganitzManaged: user.is_organitz_managed,
    isGuest: user.is_guest,
  }
}

export function mapImageURL(url: string, urlPrefix: string): string {
  if (url.startsWith('/') && !url.startsWith('//')) {
    return `${urlPrefix}${url}`
  }

  return url
}

export function mapPublication(mipasaPublication: MiPasaPublication, urlPrefix: string): Publication {
  return {
    id: mipasaPublication.id,
    projectId: mipasaPublication.project_id,
    fileId: mipasaPublication.file_id,
    versionId: mipasaPublication.version_id,
    type: mipasaPublication.type,
    accessLevel: mipasaPublication.access_level,
    publishedAt: mipasaPublication.published_at,
    publishedChangesAt: mipasaPublication.published_changes_at || mipasaPublication.published_at,
    slug: mipasaPublication.slug,
    title: mipasaPublication.title,
    description: mipasaPublication.description || undefined,
    coverImage: mipasaPublication.cover_image ? mapImageURL(mipasaPublication.cover_image, urlPrefix) : undefined,
    viewCount: mipasaPublication.view_count,
    shareCount: mipasaPublication.share_count,
    tags: mipasaPublication.tags,
    payload: mipasaPublication.payload,
    pasaInfo: mipasaPublication.pasa_info && {
      openApiSpec: mipasaPublication.pasa_info.openapi_spec,
      portfolioAssets: mipasaPublication.pasa_info.portfolio_assets,
      portfolio1D: mipasaPublication.pasa_info.portfolio_1d,
      portfolio7D: mipasaPublication.pasa_info.portfolio_7d,
      portfolio30D: mipasaPublication.pasa_info.portfolio_30d,
      portfolioMax: mipasaPublication.pasa_info.portfolio_max,
      portfolioSharpeRatio: mipasaPublication.pasa_info.portfolio_sharpe_ratio,
      portfolio1DChart: mipasaPublication.pasa_info.portfolio_1d_chart,
      portfolio7DChart: mipasaPublication.pasa_info.portfolio_7d_chart,
      portfolio30DChart: mipasaPublication.pasa_info.portfolio_30d_chart,
      portfolioMaxChart: mipasaPublication.pasa_info.portfolio_max_chart,
      portfolio1DDays: mipasaPublication.pasa_info.portfolio_1d_days,
      portfolio7DDays: mipasaPublication.pasa_info.portfolio_7d_days,
      portfolio30DDays: mipasaPublication.pasa_info.portfolio_30d_days,
      portfolioMaxDays: mipasaPublication.pasa_info.portfolio_max_days,
      portfolioAssetCategories: mipasaPublication.pasa_info.portfolio_asset_categories,
      portfolioAssetPrices: mipasaPublication.pasa_info.portfolio_asset_prices,
    },
    portfolios: mipasaPublication.portfolios,
    authors: mipasaPublication.authors.map(mapUser),
    clapsCount: mipasaPublication.claps_count,
    clappedByYou: mipasaPublication.clapped_by_you ?? false,
    owner: mapUser(mipasaPublication.owner),
    externalProviders: mipasaPublication.external_providers,
    permissions: mipasaPublication.permissions ? mapPermissions(mipasaPublication.permissions) : undefined,
    executionEnabled: mipasaPublication.execution_enabled,
    executionCopyProjectFiles: mipasaPublication.execution_copy_project_files,
    executionAutorunAfterCellChanged: mipasaPublication.execution_autorun_after_cell_changed,
  }
}

export interface MiPasaDocumentationSection {
  id: string
  index: number
  slug: string
  publication: MiPasaPublication
}

export function mapDocumentationSection(section: MiPasaDocumentationSection, urlPrefix: string) {
  return {
    id: section.id,
    index: section.index,
    slug: section.slug,
    publication: mapPublication(section.publication, urlPrefix),
  }
}

export function mapPermissions(miPasaPermissions: Record<string, boolean>): Array<ProjectPermission> {
  const permissions = Object.getOwnPropertyNames(miPasaPermissions).filter(k => miPasaPermissions[k]) as Array<ProjectPermission>

  permissions.push('view')
  return [...new Set(permissions)]
}

export function mapNotebookBase(notebook: MiPasaNotebookBase | MiPasaNotebook, mode?: ViewMode, selfId?: string): Project {
  let isTeamShared = false

  if ('permissions' in notebook && notebook.permissions) {
    isTeamShared = notebook.owned_by.id !== selfId && Object.values(notebook.permissions).some(p => p)
  }

  return {
    id: notebook.id,
    name: notebook.name,
    description: notebook.description || '',
    section: notebook.section,
    language: notebook.language || 'python',
    isPublic: notebook.is_public,
    teamShared: mode === ViewMode.shared || isTeamShared,
    parentId: notebook.parent_id,
    image: notebook.thumbnail?.icon_url,
    preview: notebook.thumbnail?.icon_url || '',
    publicGroup: mode === ViewMode.public ? 'all' : 'teams',
    publicUrl: notebook.public_url_slug || undefined,
    userId: notebook.owned_by.id,
    createdAt: new Date(notebook.created_at).getTime(),
    updatedAt: new Date(notebook.updated_at).getTime(),
    publishedAt: new Date(notebook.published_at).getTime(),
    ownedAt: new Date(notebook.owned_at).getTime(),
    githubRepo: undefined,
    githubBranch: undefined,
    totalRating: 0,
    settings: undefined,
    tags: notebook.tags || [],
    user: mapUser(notebook.owned_by),
    creator: mapUser(notebook.created_by),
    isSetupComplete: notebook.setup_complete,
    stats: {
      analyze: notebook.analyze_count,
      clone: notebook.clone_count,
      comment: notebook.comment_count,
      execute: notebook.execute_count,
      follow: notebook.follow_count,
      share: notebook.share_count,
      view: notebook.view_count,
    },
    license: notebook.license,
    defaultFileId: notebook.default_file_id,
  }
}

export function mapNotebook(notebook: MiPasaNotebook, mode?: ViewMode, selfId?: string): Project {
  return {
    ...mapNotebookBase(notebook, mode, selfId),
    permissions: mapPermissions(notebook.permissions || {}),
    isPublished: notebook.is_published,
    publishedCloneId: notebook.published_clone_id || undefined,
    authors: (notebook.authors || []).map(mapUser),
    syncConfig: notebook.sync_config && mapProjectSyncConfig(notebook.sync_config),
    portfolioMeta: notebook.portfolio_meta && mapProjectPortfolioMeta(notebook.portfolio_meta),
    latestVersionId: notebook.latest_version_id,
    latestOkVersionId: notebook.latest_ok_version_id,
  }
}

export function mapProjectSyncConfig(sync_config: MiPasaProjectSyncConfig): ProjectSyncConfig {
  return {
    id: sync_config.id,
    provider: sync_config.provider,
    repo: sync_config.repo,
    branch: sync_config.branch,
    directory: sync_config.directory,
    syncedAt: sync_config.synced_at,
    syncedVersion: sync_config.synced_version,
  }
}

export function mapProjectPortfolioMeta(portfolio_meta: MiPasaProjectPortfolioMeta): ProjectPortfolioMeta {
  return {
    assets: portfolio_meta.assets,
    holdings: portfolio_meta.holdings,
    tradeAsset: portfolio_meta.trade_asset || undefined,
    tradeAssetIcon: portfolio_meta.trade_asset_icon || undefined,
    totalDeposit: portfolio_meta.total_deposit,
    totalWithdrawal: portfolio_meta.total_withdrawal,
    exchangeType: portfolio_meta.exchange,
  }
}

export function mapRatePlan({ id, title, details, features, ...ratePlan }: MiPasaRatePlan): RatePlan {
  return {
    id,
    title,
    details,
    features,
    isHighlighted: ratePlan.is_highlighted,
    priceInUnits: ratePlan.price_in_units,
  }
}

export function mapSubscription({ id, ...subscription }: MiPasaSubscription): Subscription {
  return {
    id,
    expirationDate: subscription.expiration_date,
    nextBillingDate: subscription.next_billing_date,
    canceledAt: subscription.canceled_at,
    ratePlan: mapRatePlan(subscription.rate_plan),
    isDefault: subscription.is_default,
  }
}

export function mapExecution(miPasaRun: MiPasaNotebookRun): Execution {
  return {
    id: miPasaRun.id,
    sequence: miPasaRun.number,
    userId: miPasaRun.started_by.id,
    startedBy: mapUser(miPasaRun.started_by),
    startedAt: miPasaRun.started_at,
    endedAt: miPasaRun.ended_at,
    executionType: miPasaRun.execution_type,
    environment: miPasaRun.environment,
    version: miPasaRun.version,
    file: miPasaRun.file,
    duration: miPasaRun.ended_at ? new Date(miPasaRun.ended_at).getTime() - new Date(miPasaRun.started_at).getTime() : 0,
    executedAt: new Date(miPasaRun.started_at).getTime(),
    haveInsights: false,
    executionStatus: {
      in_progress: 'running',
      ok: 'succeeded',
      error: 'failed',
      aborted: 'failed',
    }[miPasaRun.status] as 'running' | 'succeeded' | 'failed',
  }
}

export function mapFile(miPasaFile: MiPasaFileInfo, projectId: string): File {
  const rec = getFileCodingLanguageRecord(miPasaFile.name)

  return {
    id: miPasaFile.id,
    projectId,
    name: miPasaFile.name,
    language: rec?.[0] || 'plaintext',
    description: '',
    data: '',
    isPublic: false,
    publicGroup: 'all',
    parentId: '',
    type: rec?.[1].type || CodingType.script,
    userId: 'never',
    createdAt: new Date(miPasaFile.created_at).getTime(),
    treeType: 'file',
    isConflict: false,
    metadata: miPasaFile.metadata,
  }
}

export function mapFileVersion({ id, name, created_at, revisioned_at, payload, file: { id: fileId } }: MiPasaFileVersion): FileVersion {
  return {
    id: `${fileId}/${id}`,
    fileId,
    name,
    threads: [],
    userId: 'never',
    createdAt: new Date(created_at).getTime(),
    revisionedAt: revisioned_at ? new Date(revisioned_at).getTime() : null,
    data: payload,
  }
}

export function mapDirectory(directoryName: string, projectId: string, metadata: ProjectDirectoryMetadata): File {
  return {
    id: `${projectId}/${directoryName}`,
    projectId,
    name: directoryName,
    language: 'plaintext',
    description: '',
    data: '',
    isPublic: false,
    publicGroup: 'all',
    parentId: '',
    type: CodingType.script,
    userId: 'never',
    createdAt: new Date().getTime(),
    treeType: 'directory',
    isConflict: false,
    directoryMetadata: metadata,
  }
}

export function mapComment({
  author,
  created_at,
  id,
  reaction_by_you,
  likes_count,
  dislikes_count,
  reply_to,
  content,
  replies,
  is_deleted,
}: MiPasaNotebookComment): NotebookComment {
  return {
    id,
    author: mapUser(author),
    createdAt: new Date(created_at).getTime(),
    reactionByYou: reaction_by_you,
    likesCount: likes_count,
    dislikesCount: dislikes_count,
    content,
    replyTo: reply_to,
    replies,
    isDeleted: is_deleted,
  }
}

export function mapAnalysisNode(node: NotebookAnalysisNode): NotebookAnalysisNode {
  // Have to use 3 separate cases here so TS can distinguish types from each other
  switch (node.type) {
    case NotebookAnalysisNodeType.notebook:
      return {
        ...node,
        entity: {
          ...node.entity,
          owner: mapUser(node.entity.owner as unknown as MiPasaUser),
        },
      }
    case NotebookAnalysisNodeType.dataset:
      return {
        ...node,
        entity: {
          ...node.entity,
          owner: mapUser(node.entity.owner as unknown as MiPasaUser),
        },
      }
    case NotebookAnalysisNodeType.externalDataset:
    default:
      return node
  }
}

export function mapTeamPermissions(miPasaPermissions: Record<string, boolean>): Array<TeamMemberPermission> {
  return Object.getOwnPropertyNames(miPasaPermissions).filter(name => miPasaPermissions[name]) as Array<TeamMemberPermission>
}

export function mapTeam(team: MiPasaTeam): Team {
  return {
    id: team.id,
    icon: team.thumbnail?.icon_url || undefined,
    createdAt: new Date(team.created_at).getTime(),
    updatedAt: new Date(team.updated_at).getTime(),
    createdBy: mapUser(team.created_by),
    name: team.name,
    description: team.description || undefined,
    permissions: team.permissions && mapTeamPermissions(team.permissions),
    usersCount: team.users_count || 0,
    projectsCount: team.projects_count || 0,
    isDiscoverable: team.is_discoverable,
    approvableJoin: team.approvable_join,
    contactEmail: team.contact_email || undefined,
    membershipStatus: team.membership_status,
    lastMembers: team.last_members?.map(lm => ({ user: mapUser(lm.user), permissions: lm.permissions })),
  }
}

export function mapTeamJoinRequest(joinRequest: MiPasaTeamJoinRequest): TeamJoinRequest {
  return {
    id: joinRequest.id,
    teamId: joinRequest.team_id,
    createdAt: new Date(joinRequest.created_at).getTime(),
    status: joinRequest.status,
    user: mapUser(joinRequest.user),
    reason: joinRequest.reason,
  }
}

export function mapProjectUserAccess(userPermission: MiPasaUserPermission): ProjectUserAccess {
  return {
    id: userPermission.id,
    createdAt: userPermission.created_at,
    permissions: userPermission.permissions as NotebookPermission[],
    user: mapUser(userPermission.user),
    author: userPermission.author,
    isOwner: userPermission.is_owner,
  }
}

export function mapProjectTeamAccess(teamPermission: MiPasaTeamPermission): ProjectTeamAccess {
  return {
    id: teamPermission.id,
    permissions: teamPermission.permissions as NotebookPermission[],
    team: mapTeam(teamPermission.team),
    createdAt: teamPermission.created_at,
  }
}

export function mapTeamMember(teamMember: MiPasaTeamMember): TeamMember {
  return {
    id: teamMember.id,
    permissions: teamMember.permissions,
    user: mapUser(teamMember.user),
    createdAt: teamMember.created_at,
  }
}

export function mapMiPasaTeamMemberRequest(teamMemberRequest: TeamMemberRequest): MiPasaTeamMemberRequest {
  return {
    permissions: teamMemberRequest.permissions,
    user_id: teamMemberRequest.userId,
  }
}

export function mapProjectAccessRequest({ id, user, created_at, reason, status }: MiPasaProjectAccessRequest): ProjectAccessRequest {
  return {
    id,
    reason,
    status,
    createdAt: new Date(created_at).getTime(),
    user: mapUser(user),
  }
}

export function mapProjectInvite(invite: MiPasaProjectInvite): ProjectInvite {
  return {
    id: invite.id,
    email: invite.email,
    permissions: invite.permissions,
    createdAt: invite.created_at,
  }
}

export function mapFeedEntry(feedEntry: MiPasaFeedEntry): FeedEntry {
  return {
    id: feedEntry.id,
    action: feedEntry.action,
    target: {
      id: feedEntry.target.id,
      type: feedEntry.target.type,
      name: feedEntry.target.name,
    },
    performedAt: feedEntry.performed_at,
    user: mapUser(feedEntry.user),
    performedBy: feedEntry.performed_by && mapUser(feedEntry.performed_by),
  }
}

export function mapTeamInvite(invite: MiPasaTeamInvite): TeamInvite {
  return {
    id: invite.id,
    email: invite.email,
    permissions: invite.permissions,
    createdAt: invite.created_at,
  }
}

export function mapInviteLocation(inviteLocation: MiPasaInviteLocation): InviteLocation {
  switch (inviteLocation.type) {
    case 'single_team':
      return { type: InviteLocationType.team, id: inviteLocation.id }

    case 'single_notebook':
      return { type: InviteLocationType.project, id: inviteLocation.id }

    default:
      return { type: InviteLocationType.generic }
  }
}

export function mapInvite(invite: MiPasaInvite): Invite {
  return {
    id: invite.id,
    email: invite.email,
    createdAt: invite.created_at,
    to: mapInviteLocation(invite.to),
  }
}

export function mapTeamNotebook(teamNotebook: MiPasaTeamNotebook): TeamProject {
  return {
    ...mapNotebook(teamNotebook),
    teamPermissionId: teamNotebook.team_permission_id,
  }
}

export function mapUserFollow(userFollow: MiPasaUserFollow): UserFollow {
  return {
    follower: mapUser(userFollow.follower),
    followingByYou: userFollow.following_by_you,
  }
}

export function mapGitHubConnection(connection: MiPasaGitHubConnection): GitHubConnection {
  return {
    isConnected: connection.is_connected,
    userName: connection.user_name,
    avatar: connection.avatar,
  }
}

export function mapBinanceConnection(connection: MiPasaBinanceConnection): BinanceConnection {
  return {
    isConnected: connection.is_connected,
  }
}

export function mapTradingAsset(tradingAsset: MiPasaTradingAsset): TradingAsset {
  return {
    remoteType: tradingAsset.remote_type,
    remoteId: tradingAsset.remote_id,
    symbol: tradingAsset.symbol,
    description: tradingAsset.description,
    imageUrl: tradingAsset.image_url || undefined,
    marketCap: tradingAsset.market_cap,
    price: tradingAsset.price,
    age: tradingAsset.age,
  }
}

export function mapTradingAssetData(tradingAsset: MiPasaTradingAssetData): TradingAssetData {
  return {
    marketCaps: tradingAsset.market_caps,
    prices: tradingAsset.prices,
    totalVolumes: tradingAsset.total_volumes,
  }
}

export function mapProjectSyncResponse(response: MiPasaProjectSyncResponse): ProjectSyncResponse {
  return {
    syncConfig: mapProjectSyncConfig(response.sync_config),
    localChanges: response.local_changes,
    remoteChanges: response.remote_changes,
  }
}

export function mapNotificationEntry(notificationEntry: MiPasaNotificationEntry): NotificationEntry {
  return {
    id: notificationEntry.id,
    type: notificationEntry.type,
    createdAt: notificationEntry.created_at,
    metadata: notificationEntry.metadata,
    entity: notificationEntry.entity && {
      id: notificationEntry.entity.id,
      type: notificationEntry.entity.type,
      name: notificationEntry.entity.name,
      link: notificationEntry.entity.link,
      slug: notificationEntry.entity.slug,
    },
    seenAt: notificationEntry.seen_at,
    sourceUser: notificationEntry.source_user && mapUser(notificationEntry.source_user),
    subjectUser: notificationEntry.subject_user && mapUser(notificationEntry.subject_user),
    subjectTeam: notificationEntry.subject_team && { id: notificationEntry.subject_team.id, name: notificationEntry.subject_team.name },
  }
}

export function mapTrialInformation(miPasaInfo: MiPasaTrialInformation): TrialInformation {
  return {
    enabled: miPasaInfo.enabled,
    expiresAt: miPasaInfo.expires_at || undefined,
    expiryDays: miPasaInfo.expiry_days ? parseInt(miPasaInfo.expiry_days, 10) : undefined,
    disabledReason: miPasaInfo.disabled_reason || undefined,
    ratePlanId: miPasaInfo.rate_plan_id || undefined,
    features: miPasaInfo.features,
  }
}

export function mapSelf(miPasaSelf: MiPasaSelf, preferences?: UserPreferences, gitHub?: MiPasaGitHubConnection): Self {
  const roles = miPasaSelf.user.admin ? ['registered', 'domain-admin'] : ['registered']
  const actualGitHub = miPasaSelf.github || gitHub

  return {
    signedIn: true,
    authenticationError: miPasaSelf.auth_error || undefined,
    sessionExpired: false,
    token: miPasaSelf.token,
    apiKey: miPasaSelf.api_key,
    user: {
      firstName: miPasaSelf.user.first_name,
      lastName: miPasaSelf.user.last_name,
      balance: 0,
      headline: miPasaSelf.user.headline || '',
      twitterUrl: miPasaSelf.user.social.twitter_url || '',
      linkedInUrl: miPasaSelf.user.social.linkedin_url || '',
      blogUrl: miPasaSelf.user.social.blog_url || '',
      websiteUrl: miPasaSelf.user.social.website_url || '',
      facebookUrl: miPasaSelf.user.social.facebook_url || '',
      company: miPasaSelf.user.company.name || '',
      companyPosition: miPasaSelf.user.company.position || '',
      country: '',
      roles,
      id: miPasaSelf.user.id,
      avatar: miPasaSelf.user.avatar,
      avatarIcon: miPasaSelf.user.avatar_icon,
      credentials: {
        id: miPasaSelf.user.id,
        name: miPasaSelf.user.user_name,
        email: miPasaSelf.user.email,
        phone: '',
        userId: miPasaSelf.user.id,
        confirmedAt: miPasaSelf.user.email_is_confirmed ? new Date().getTime() : undefined,
      },
      githubUsername: actualGitHub?.user_name || undefined,
      githubAvatar: actualGitHub?.avatar || undefined,
      githubOAuthToken: actualGitHub?.is_connected ? 'present' : undefined,
      slackTeamName: miPasaSelf.slack?.team_name || undefined,
      isDiscoverable: miPasaSelf.user.is_discoverable,
      preferences: miPasaSelf.preferences || preferences,
      features: miPasaSelf.features,
      isSuspended: miPasaSelf.user.is_suspended,
      referralCode: miPasaSelf.user.referral_code,
      isIDMManaged: miPasaSelf.user.is_idm_managed,
      isTelegramManaged: miPasaSelf.user.is_telegram_managed,
      isOrganitzManaged: miPasaSelf.user.is_organitz_managed,
    },
    session: {
      applicationId: 'mipasa',
      admin: !!miPasaSelf.user.admin,
      adminId: miPasaSelf.masked ? 'masked-admin-id' : undefined,
      confirmed: miPasaSelf.user.email_is_confirmed,
      created: new Date().getTime(),
      id: miPasaSelf.user.id,
      expiration: new Date().getTime() + 86400 * 1000 * 365, // "expires" in a year
      lastActivity: new Date().getTime(),
      miPasaAuthorized: true,
      remote: true,
      isGuest: Boolean(miPasaSelf.is_guest),
      guestId: miPasaSelf.guest_id || undefined,
      roles,
    },
    trial: miPasaSelf.trial ? mapTrialInformation(miPasaSelf.trial) : undefined,
  }
}

export interface MiPasaTradingBotChannel {
  id: string
  inserted_at: string
  type: 'private' | 'channel' | 'group'
  title: string
  telegram_chat_id: string
  periodic_updates_enabled: boolean
  periodic_updates_interval: 'daily' | 'weekly'
}

export function mapTradingBotChannel(channelRaw: MiPasaTradingBotChannel): TradingBotChannel {
  return {
    id: channelRaw.id,
    insertedAt: channelRaw.inserted_at,
    type: channelRaw.type,
    title: channelRaw.title,
    telegramChatId: channelRaw.telegram_chat_id,
    periodicUpdatesEnabled: channelRaw.periodic_updates_enabled,
    periodicUpdatesInterval: channelRaw.periodic_updates_interval,
  }
}

export function createPaginationParams<T extends PaginationOptions>(opts: T) {
  const params = new URLSearchParams()
  opts.page && params.set('page', String(opts.page))
  opts.perPage && params.set('page-size', String(opts.perPage))
  return params
}

export interface MiPasaReferral {
  id: string
  inserted_at: string
  code: string
  units: number
  enabled: boolean
  roles: Array<string> | null
  rate_plan_id: string | null
  expires_at: string | null
  trial_days: number | null
}

export function mapReferral(miPasaReferral: MiPasaReferral): Referral {
  return {
    id: miPasaReferral.id,
    insertedAt: miPasaReferral.inserted_at,
    code: miPasaReferral.code,
    units: miPasaReferral.units,
    enabled: miPasaReferral.enabled,
    roles: miPasaReferral.roles || undefined,
    ratePlanId: miPasaReferral.rate_plan_id || undefined,
    expiresAt: miPasaReferral.expires_at || undefined,
    trialDays: miPasaReferral.trial_days || undefined,
  }
}

export function mapReferralUser(miPasaRefUser: MiPasaAdminReferralUser): ReferralUser {
  return {
    user: mapAdminUser(miPasaRefUser.user),
    trial: mapTrialInformation(miPasaRefUser.trial),
  }
}
